<template>
  <div>
    <Header></Header>
    <div class="lone">
      <div class="banner">
        <div class="main_left">
          <div
            class="nav"
            v-for="(item, index) in navarr[0][num]"
            :key="index"
            @click="router(index)"
          >
            <div class="flex_row">
              <div>{{ item }}</div>
              <div>></div>
            </div>
          </div>
        </div>
        <div class="main_right">
          <router-view></router-view>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/Herder.vue";
import Footer from "../components/Footer.vue";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["num"]),
  },
  components: { Header, Footer },
  data() {
    return {
      navarr: [
        {
          0: ["关于苏梅岛", "项目介绍", "设计团队", "九大亮点", "中国业主篇"],

          1: ["About", "Condo", "Architects", "9 Highlights"],
        },
      ],
    };
  },
  methods: {
    router(index) {
      switch (index) {
        case 0:
          {
            this.$router.push("/particulars");
          }
          break;
        case 1:
          {
            this.$router.push("/introduce");
          }
          break;
        case 2:
          {
            this.$router.push("/team");
          }
          break;
        case 3:
          {
            this.$router.push("/lightspot");
          }
          break;
        case 4:
          {
            this.$router.push("/china");
          }
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.lone {
  width: 100%;
  background-color: #f3f3f4;
  overflow: hidden;
}
.flex_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 0.65rem;
  padding-left: 0.5rem;
  padding-right: 0.2rem;
}
.nav {
  width: 2.7rem;
  height: 0.65rem;
  background: white;
  margin-bottom: 0.05rem;
}
.nav:hover {
  background-color: #a08e75;
  color: white;
}
.banner {
  width: 12.6rem;

  margin: 1rem auto;
  display: flex;
}
.main_left {
  width: 3.47rem;
  height: 100%;
  border-right: 0.02rem solid #dad5ce;
}
.main_right {
  flex: 1;
}
/deep/b{
  font-weight: 700 !important;
}
</style>